@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100;200;300;400;500;700&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Geologica', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight:300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.subtitle {
  color:rgb(102, 102, 102);
  font-size:16px;
}

.card-title {
  font-size:17px;
  margin-bottom: 4px;
  font-weight:500;
  color:black;
}

.menu-item {
  display: block;
  border-radius: 0.5rem;
  padding-left: 1rem; 
  padding-right: 1rem; 
  padding-top: 0.5rem; 
  padding-bottom: 0.5rem; 
  font-size: 0.875rem; 
  line-height: 1.25rem; 
  font-weight: 500;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}

.rizzui-alert-content {
  padding-inline-start : 0!important
}